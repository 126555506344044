<template>
  <template v-if="isOptimize && isProd">
    <img v-img :src="`${urlBase}.webp`" :alt="alt" loading="lazy" />
  </template>
  <template v-else>
    <img v-img :src="src" :alt="alt" loading="lazy" />
  </template>
</template>
<script setup lang="ts">
import { computed } from '#imports'

const OPTIMIZE_EXTENSIONS_REG = /jpg|jpeg|png|gif/g
const props = defineProps<{
  src: string
  alt: string
}>()
const urlBase = computed(() => props.src.split('.').slice(0, -1).join('.'))
const isOptimize = computed(() => {
  const extension = props.src.split('.').pop()
  if (!extension) return false
  return extension.match(OPTIMIZE_EXTENSIONS_REG)
})
const isProd = process.env.NODE_ENV === 'production'
</script>
